<template>
  <div class="marketing-view">
    <Container>
      <Separator size="medium" />
      <Margins>
        <h3>ANNA AMMATTILAISEN TEHDÄ TYÖ</h3>
        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <p>
                Kuvittele, että sinulla olisi oma puutarhuri, joka huolehtisi
                aina siitä, että nurmikkosi on leikattu täydellisesti. STIHL All
                Inclusive -palvelu tekee iMOW®-robottiruohonleikkurin
                omistamisesta helppoa ja mukavaa.
              </p>
              <p>
                Kaikki sisältyy kiinteään kuukausihintaan- asennus, huollot ja
                talvisäilytys. Voit valita malleista sen, joka sopii sinun ja
                nurmikkosi tarpeisiin parhaiten. Kiinteä kuukausimaksu lasketaan
                valitsemasi tuotteen mukaan, ja maksu jaetaan 60 kuukaudelle. Et
                maksa korkoja, perustamiskuluja tai jäännösarvoa. Hinta, jonka
                maksat rahoituskauden aikana, on hinta, jonka maksaisit
                robottiruohonleikkurista, asennuksesta, talvihuollosta ja
                talvisäilytyksestä.
              </p>
              <p>Rahoitus toteutetaan yhteistyössä Resurs Bankin kanssa.</p>
            </Margins>
          </b-col>

          <b-col cols="6" sm="3" xl="3">
            <img
              :data-src="require('@/assets/images/sai_lp_s1_imows.png')"
              alt="Stihl All Inclusive price"
              class="lazyload margins__mobile-sm"
            />
          </b-col>
        </b-row>
      </Margins>
    </Container>

    <Separator size="medium" />

    <div id="map">
      <SelectStore view />
    </div>
  </div>
</template>

<script>
import { Container, Separator, Margins } from '@/components';
import SelectStore from '@/views/SelectStore/SelectStore';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapGetters } from 'vuex';
import { COOKIE_CONSENT_LEVELS } from '@/constants';

export default {
  name: 'MarketingView',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Margins,
    SelectStore,
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1);
    }
  },
  methods: {
    scrollTo(hashtag) {
      setTimeout(() => {
        window.location.href = hashtag;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(['hasConsentByLevel']),
    listItems() {
      return [
        'Ota yhteyttä lähimpään <a href="https://stihlallinclusive.fi/select-store">STIHL All Inclusive -jälleenmyyjään</a> ja valitse tarpeisiisi sopiva iMOW®-malli.',
        'Jälleenmyyjä asentaa uuden iMOW®:n puutarhaasi ja varmistaa, että kaikki toimii kuten pitääkin.* ',
        'Maksa kiinteät kuukausimaksut. Huolto ja talvisäilytys sisältyvät hintaan 4 vuoden ajan.',
        'Uusi sopimuksesi ja saat uuden iMOW®:n 4 vuoden kuluttua. Vaihtoehtoisesti voit pitää nykyisen koneesi omanasi.',
      ];
    },
    returnChecklist() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_CHECKLIST_ITEMS'),
      ).map((r) => r);
    },
    returnItems() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_ITEMS_ITEMS'),
      ).map((r) => r);
    },
    hasTargetingConsentLevel() {
      return this.hasConsentByLevel(COOKIE_CONSENT_LEVELS.TARGETING);
    },
  },
};
</script>
